import { Hero } from "../components/hero/hero";
import { BlockContent } from "~/components/block-content";
import { Container } from "../components/ui/container";
import type { TermSingleQuery } from "~/types/queries";
import { asText } from "~/sanity/sanity-helpers";
import { formatDate } from "~/utils/misc";

interface Props {
	data: TermSingleQuery;
}

export function TermSingle({ data }: Props) {
	const { title, subtitle, publishedAt, content, __i18n_lang } = data;

	return (
		<>
			<Hero
				variant="compact"
				title={asText(title)}
				description={subtitle}
				tagline={publishedAt ? formatDate(publishedAt, __i18n_lang) : ""}
			/>
			<Container className="!max-w-content">
				<BlockContent
					value={content}
					paragraphColor="primary"
					tableClassName="!table-auto"
				/>
			</Container>
		</>
	);
}
