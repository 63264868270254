import { getPageData, getExperimentData } from "~/queries/page.server";
import { getPreview, checkValidPreview } from "~/sanity/sanity.server";
import { getLanguage } from "~/utils/language";
import { getPricingSectionData } from "~/queries/pricing.server";
import { getRelatedPostsSectionData } from "~/queries/blog.server";
import { invariantResponse } from "~/utils/misc";

import { reuseSeoMeta, reuseHeaders } from "~/utils/misc";
import { PageSingle } from "~/templetes/page-single";
import Preview from "~/components/preview";
import { TermSingle } from "~/templetes/terms-single";
import { GenericErrorBoundary } from "~/components/errors";
import type { PageDocumentBase } from "~/types/sanity-schema";
import type { PageSingleQuery, TermSingleQuery } from "~/types/queries";
import { PageAddOns } from "~/components/PageAddOns";
import { isProductPageDocument } from "~/sanity/sanity-helpers";
import type { PostPreview } from "~/types/post";
import type { PageData } from "~/types";
import type { PricingRelatedData } from "~/types/product";
import type { Route } from "./+types/generic-page";
import { getEventsByTag } from "~/queries/events.server";
import { EventDocument } from "~/types/event";

export const loader = async ({ params, request }: Route.LoaderArgs) => {
	const slug = params["*"];

	const lang = getLanguage(request);

	invariantResponse(typeof slug === "string", "slug must be a string");

	const { isPreview } = getPreview(request);

	const { experimentSlug, experiment } = await getExperimentData({
		slug,
		request,
	});

	const pageData = await getPageData({
		lang,
		slug,
		experimentSlug,
		isPreview,
	});

	const { data } = pageData;
	pageData.experiment = experiment;
	if (!data) {
		throw new Response(`Page not found`, { status: 404 });
	}

	checkValidPreview(request, data);

	const pricingRelatedData = await getPricingSectionData(data, request);
	const relatedPostData =
		(await getRelatedPostsSectionData(data, request, isPreview)) || [];

	const relatedEvents = data.tags
		? await getEventsByTag(lang, data.tags, isPreview)
		: [];

	const loaderData = {
		pageData,
		pricingRelatedData,
		relatedPostData,
		relatedEvents,
	};

	return loaderData;
};

export const meta = reuseSeoMeta;

export const headers = reuseHeaders;

type LoaderData = {
	pageData: PageData;
	pricingRelatedData: PricingRelatedData;
	relatedPostData: Array<PostPreview>;
	relatedEvents: EventDocument[];
};

export default function Page({ loaderData }: { loaderData: LoaderData }) {
	const { pageData, pricingRelatedData, relatedPostData, relatedEvents } =
		loaderData;

	const { data, query, queryParams, isPreview } = pageData;

	function renderPage(data: PageDocumentBase) {
		switch (data._type) {
			case "page":
			case "timeSeries":
			case "solution":
				return (
					<PageSingle
						data={data as PageSingleQuery}
						additionalData={{
							pricingRelatedData,
							relatedPostData,
							relatedEvents,
						}}
					/>
				);
			case "product":
				if (!isProductPageDocument(data)) {
					return null;
				}

				const supportedVersion = data.service?.supportedVersion;
				const externalId = data.service?.externalId?.current;

				return (
					<PageSingle
						data={data as PageSingleQuery}
						additionalData={{
							pricingRelatedData,
							supportedVersion,
							externalId,
							relatedPostData,
							relatedEvents,
						}}
					/>
				);
			case "term":
				return <TermSingle data={data as TermSingleQuery} />;
		}
	}

	return (
		<Preview
			initialData={data}
			query={query}
			queryParams={queryParams}
			isPreview={isPreview}
		>
			{(currentData) => {
				return (
					<div
						style={
							data?.backgroundColor
								? { backgroundColor: data?.backgroundColor }
								: {}
						}
					>
						<PageAddOns pageBaseData={currentData} />
						{renderPage(currentData)}
					</div>
				);
			}}
		</Preview>
	);
}

export function ErrorBoundary() {
	return <GenericErrorBoundary />;
}
